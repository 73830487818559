import axios from 'axios';
import { Resources, } from '@hankintailmoitukset.fi/hilma-espd-edm';
import { trackException } from '@/tracking/applicationinsights';
export function getEspdCriteria() {
    return Resources.criterionSpecification;
}
export async function createEspdResponse(host, payload) {
    const url = `${host}api/responses`;
    const result = await axios.post(url, payload);
    return result.data;
}
export async function saveEspdResponse(identifier, host, payload) {
    const url = `${host}api/responses/${identifier}`;
    await axios.put(url, payload);
}
export async function saveEspdResponseAnonymous(identifier, host, payload, accessToken) {
    const url = `${host}api/anonymousresponses/${identifier}?accessToken=${accessToken}`;
    await axios.put(url, payload, {
        flags: {
            disableBearerAuthentication: true,
        },
    });
}
export async function getEspdResponse(identifier, host) {
    const url = `${host}api/responses/${identifier}`;
    try {
        const returned = await axios.get(url);
        return returned.data;
    }
    catch (error) {
        trackException(error, { info: 'getEspdResponse' });
        return undefined;
    }
}
export async function getEspdResponseAnonymous(identifier, host, accessToken) {
    const url = `${host}api/anonymousresponses/${identifier}?accessToken=${accessToken}`;
    try {
        const returned = await axios.get(url, {
            flags: {
                disableBearerAuthentication: true,
            },
        });
        return returned.data;
    }
    catch (error) {
        trackException(error, { info: 'getEspdResponseAnonymous' });
        return undefined;
    }
}
export async function getResponseXmlAnonymous(id, host, accessToken) {
    const url = `${host}api/anonymousresponses/${id}/xml?accessToken=${accessToken}`;
    try {
        const returned = await axios.get(url);
        return returned.data;
    }
    catch (error) {
        trackException(error, { info: 'getResponseXmlAnonymous' });
        return undefined;
    }
}
export async function getEspdResponseForReview(identifier, host) {
    const url = `${host}api/responses/review/${identifier}`;
    try {
        const returned = await axios.get(url);
        return returned.data;
    }
    catch (error) {
        trackException(error, { info: 'getEspdResponseForReview' });
        return undefined;
    }
}
export async function getMyEspdResponseByRequestId(uuid, host) {
    const url = `${host}api/responses/requests/${uuid}/mine`;
    try {
        const returned = await axios.get(url);
        return returned.data;
    }
    catch (error) {
        trackException(error, { info: 'getMyEspdResponseByRequestId' });
        return undefined;
    }
}
export function getAccessTokenQueryParameter(query) {
    const keys = Object.keys(query);
    for (let i = 0; i < keys.length; i++) {
        if (keys[i].toLocaleLowerCase() === 'accesstoken') {
            return query[keys[i]];
        }
    }
    return undefined;
}
export async function importXMLResponse(identifier, host, file) {
    const url = `${host}api/responses/${identifier}/xmlimport`;
    const formData = new FormData();
    formData.append('file', file);
    const returned = await axios.put(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return returned.data;
}
export async function importXMLResponseAnonymous(identifier, host, file, accessToken) {
    const url = `${host}api/anonymousresponses/${identifier}/xmlimport?accessToken=${accessToken}`;
    const formData = new FormData();
    formData.append('file', file);
    const returned = await axios.put(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return returned.data;
}
