import { PublicClientApplication, } from '@azure/msal-browser';
import router from '../router';
export var LoginType;
(function (LoginType) {
    LoginType[LoginType["Request"] = 1] = "Request";
    LoginType[LoginType["Response"] = 2] = "Response";
    LoginType[LoginType["ResponseReview"] = 3] = "ResponseReview";
})(LoginType || (LoginType = {}));
export class AuthService {
    app = null;
    account = null;
    loginScopes = ['openid'];
    logoutUrl = '';
    initialize(authenticationConfig, newScopes) {
        if (typeof authenticationConfig.auth.postLogoutRedirectUri === 'string') {
            this.logoutUrl = authenticationConfig.auth.postLogoutRedirectUri;
        }
        this.loginScopes = newScopes;
        const app = new PublicClientApplication(authenticationConfig);
        this.app = app;
        return new Promise((resolve, reject) => {
            app.initialize().then(() => {
                app.handleRedirectPromise()
                    .then((response) => {
                    if (response) {
                        this.account = response.account;
                        const route = JSON.parse(response.state || '{}');
                        router.push(route).then(() => resolve(app));
                    }
                    else {
                        const accounts = app.getAllAccounts();
                        if (accounts.length > 1) {
                            throw new Error('Multiple accounts detected. Please sign out all accounts and try again.');
                        }
                        else if (accounts.length > 0) {
                            this.account = accounts[0];
                        }
                        resolve(app);
                    }
                })
                    .catch(reject);
            }).catch(reject);
        });
    }
    getAccount() {
        return this.account;
    }
    async acquireToken() {
        const request = {
            scopes: this.loginScopes,
            account: this.account,
        };
        try {
            const response = await this.app.acquireTokenSilent(request);
            return response.accessToken;
        }
        catch (err) {
            try {
                const response = await this.app.acquireTokenPopup(request);
                return response.accessToken;
            }
            catch (e) {
                console.error(e);
            }
        }
        return undefined;
    }
    async login(params) {
        const redirectRoute = {
            name: params.redirectRouteName,
            params: {
                lang: params.lang,
                documentId: params.documentId,
                msp: params.msp,
            },
        };
        const request = {
            scopes: this.loginScopes,
            extraQueryParameters: {
                ui_locales: params.lang,
                espdRequestedProject: params.loginType !== LoginType.Response ? params.projectId : '0',
                espdRequestedProcedure: params.loginType !== LoginType.Response ? params.procedureId : '0',
                msp: params.msp.toString(),
            },
            state: JSON.stringify(redirectRoute),
            redirectStartPage: window.location.href,
        };
        switch (this.app.getConfiguration().cache?.cacheLocation) {
            case 'sessionStorage':
                sessionStorage.clear();
                break;
            case 'localStorage':
                localStorage.clear();
                break;
            default:
                break;
        }
        localStorage.setItem('login-locale', request.extraQueryParameters?.ui_locales ?? '');
        localStorage.setItem('msp', request.extraQueryParameters?.msp ?? '');
        await this.app.loginRedirect(request);
    }
    async logout(redirect = undefined) {
        const logoutOptions = {};
        if (redirect) {
            this.logoutUrl = redirect;
            logoutOptions.postLogoutRedirectUri = redirect;
        }
        await this.app.logoutRedirect(logoutOptions);
    }
}
export const instance = new AuthService();
export function isInIframe() {
    try {
        return window.self !== window.top;
    }
    catch (e) {
        return true;
    }
}
