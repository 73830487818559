import Vue from 'vue';
import Vuex from 'vuex';
import configuration from '@/config';
import { getCpvCodes, getCpvTree } from '@/services/cpv';
import { getEspdRequest, getEspdCriteria, saveEspdRequest, } from '../services/espd-requests';
import { getEspdResponse, getEspdResponseAnonymous, getMyEspdResponseByRequestId, saveEspdResponse, saveEspdResponseAnonymous, createEspdResponse, getEspdResponseForReview, getAccessTokenQueryParameter, importXMLResponse, importXMLResponseAnonymous, getResponseXmlAnonymous, } from '../services/espd-responses';
import { getPublicRequest, getPublicRequestXml, getResponseXml, } from '../services/public-requests';
import { getEvidencesForCriterion } from '../services/ecertis-evidences';
Vue.use(Vuex);
export const setEspdCriteria = 'setEspdCriteria';
export default new Vuex.Store({
    state: {
        configuration: configuration.configuration,
        espdCriteria: undefined,
    },
    getters: {
        serviceUrl(state) {
            if (state.configuration?.espdServiceHost) {
                return state.configuration.espdServiceHost;
            }
            throw new Error('EsdpServiceHost configuration not found!');
        },
        hilmaWebUrl(state) {
            if (state.configuration?.hilmaWebUrl) {
                return state.configuration.hilmaWebUrl;
            }
            throw new Error('HilmaWebUrl configuration not found!');
        },
        publicServiceUrl(state) {
            if (state.configuration?.espdPublicServiceUrl) {
                return state.configuration.espdPublicServiceUrl;
            }
            throw new Error('EspdPublicServiceUrl configuration not found!');
        },
        ecertisEndpoint(state) {
            if (state.configuration?.ecertisEndpoint) {
                return state.configuration.ecertisEndpoint;
            }
            throw new Error('EcertisEndpoint configuration not found');
        },
    },
    mutations: {
        [setEspdCriteria](state, payload) {
            state.espdCriteria = payload;
        },
    },
    actions: {
        async getEspdRequest({ state }, identifier) {
            return getEspdRequest(identifier, state.configuration.espdServiceHost);
        },
        async saveEspdRequest({ state }, payload) {
            await saveEspdRequest(payload[0], state.configuration.espdServiceHost, payload[1]);
        },
        async getEspdResponse({ state }, identifier) {
            const accessToken = getAccessTokenQueryParameter(state.route.query);
            if (accessToken) {
                return getEspdResponseAnonymous(identifier, state.configuration.espdServiceHost, accessToken);
            }
            return getEspdResponse(identifier, state.configuration.espdServiceHost);
        },
        async createEspdResponse({ state }, payload) {
            return createEspdResponse(state.configuration.espdServiceHost, payload);
        },
        async saveEspdResponse({ state }, payload) {
            const accessToken = getAccessTokenQueryParameter(state.route.query);
            if (accessToken) {
                await saveEspdResponseAnonymous(payload[0], state.configuration.espdServiceHost, payload[1], accessToken);
            }
            else {
                await saveEspdResponse(payload[0], state.configuration.espdServiceHost, payload[1]);
            }
        },
        async getMyEspdResponseByRequestId({ state }, identifier) {
            return getMyEspdResponseByRequestId(identifier, state.configuration.espdServiceHost);
        },
        async getEspdCriteria({ state, commit }) {
            if (!state.espdCriteria?.length) {
                const found = getEspdCriteria();
                commit(setEspdCriteria, found);
            }
            return state.espdCriteria;
        },
        async getEspdResponseForReview({ state }, identifier) {
            return getEspdResponseForReview(identifier, state.configuration.espdServiceHost);
        },
        async getPublicRequest({ state }, uuid) {
            return getPublicRequest(uuid, state.configuration?.espdPublicServiceUrl);
        },
        async getPublicRequestXml({ state }, uuid) {
            return getPublicRequestXml(uuid, state.configuration?.espdPublicServiceUrl);
        },
        async getResponseXml({ state }, id) {
            const accessToken = getAccessTokenQueryParameter(state.route.query);
            if (accessToken) {
                return getResponseXmlAnonymous(id, state.configuration?.espdServiceHost, accessToken);
            }
            return getResponseXml(id, `${state.configuration?.espdServiceHost}api/`);
        },
        async getCpvCodes({ state }, lang) {
            return getCpvCodes(lang ?? state.route.params.lang, state.configuration?.hilmaApiEndpoint);
        },
        async getCpvTree({ state }, lang) {
            return getCpvTree(lang, state.configuration?.hilmaApiEndpoint);
        },
        async getEvidencesForCriterion({ state }, payload) {
            return getEvidencesForCriterion(payload.criterionId, state.configuration?.ecertisEndpoint, payload.countryFilter, payload.lang);
        },
        async importXMLResponse({ state }, payload) {
            const accessToken = getAccessTokenQueryParameter(state.route.query);
            if (accessToken) {
                return importXMLResponseAnonymous(payload.id, state.configuration?.espdServiceHost, payload.file, accessToken);
            }
            return importXMLResponse(payload.id, state.configuration?.espdServiceHost, payload.file);
        },
    },
    modules: {},
});
