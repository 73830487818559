import axios from 'axios';
import { trackException } from '@/tracking/applicationinsights';
export async function getPublicRequest(uuid, publicServiceUrl) {
    const url = `${publicServiceUrl}requests/${uuid}`;
    try {
        const returned = await axios.get(url);
        return returned.data;
    }
    catch (error) {
        trackException(error, { info: 'getPublicRequest' });
        return undefined;
    }
}
export async function getPublicRequestXml(uuid, publicServiceUrl) {
    const url = `${publicServiceUrl}requests/${uuid}/xml`;
    try {
        const returned = await axios.get(url);
        return returned.data;
    }
    catch (error) {
        trackException(error, { info: 'getPublicRequestXml' });
        return undefined;
    }
}
export async function getResponseXml(id, publicServiceUrl) {
    const url = `${publicServiceUrl}responses/${id}/xml`;
    try {
        const returned = await axios.get(url);
        return returned.data;
    }
    catch (error) {
        trackException(error, { info: 'getResponseXml' });
        return undefined;
    }
}
