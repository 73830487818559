import Vue from 'vue';
import VueRouter from 'vue-router';
import VueI18n from '@/i18n';
import store from '@/store';
Vue.use(VueRouter);
export const requestEditRoute = 'request-edit';
export const requestSavedRoute = 'request-saved';
export const responseEditRoute = 'response-edit';
export const responseCreateRoute = 'response-create';
export const loginToB2CRequestRoute = 'login-to-b2c-request';
export const loginToB2CResponseRoute = 'login-to-b2c-response';
export const loginToB2CCreateResponseRoute = 'login-to-b2c-create-response';
export const loginToB2CResponseReviewRoute = 'login-to-b2c-response-review';
export const loginToB2CPreviewResponseRoute = 'login-to-b2c-forwarded-response-review';
export const loginFromB2CRoute = 'login-from-b2c';
export const publicRequestRoute = 'public-request';
export const responseReviewRoute = 'response-review';
export const responsePreviewRoute = 'response-preview';
const routes = [
    {
        path: '/login',
        name: loginFromB2CRoute,
        component: () => import('@/views/LoginFromB2C.vue'),
        meta: {
            blank: true,
        },
    },
    {
        path: '/:lang/project/:projectId/notice/:noticeId/request/:documentId',
        name: loginToB2CRequestRoute,
        props: true,
        component: () => import('@/views/LoginToB2C.vue'),
        meta: {
            blank: true,
        },
    },
    {
        path: '/:lang/procedure/:procedureId/enotice/:enoticeId/request/:documentId',
        name: loginToB2CRequestRoute,
        props: true,
        component: () => import('@/views/LoginToB2C.vue'),
        meta: {
            blank: true,
        },
    },
    {
        path: '/:lang/auth/request/:documentId/response',
        name: loginToB2CCreateResponseRoute,
        props: true,
        component: () => import('@/views/LoginToB2C.vue'),
        meta: {
            blank: true,
        },
    },
    {
        path: '/:lang/auth/response/:documentId',
        name: loginToB2CResponseRoute,
        props: true,
        component: () => import('@/views/LoginToB2C.vue'),
        meta: {
            blank: true,
        },
    },
    {
        path: '/:lang/project/:projectId/notice/:noticeId/response-review/:documentId',
        name: loginToB2CResponseReviewRoute,
        props: true,
        component: () => import('@/views/LoginToB2C.vue'),
        meta: {
            blank: true,
        },
    },
    {
        path: '/:lang/procedure/:procedureId/notice/:noticeId/response-review/:documentId',
        name: loginToB2CResponseReviewRoute,
        props: true,
        component: () => import('@/views/LoginToB2C.vue'),
        meta: {
            blank: true,
        },
    },
    {
        path: '/:lang/response-preview/:documentId',
        name: loginToB2CPreviewResponseRoute,
        props: true,
        component: () => import('@/views/LoginToB2C.vue'),
        meta: {
            blank: true,
        },
    },
    {
        path: '/:lang',
        beforeEnter(to) {
            window.location.href = `${store.state.configuration.hilmaWebUrl}${to.params.lang}`;
        },
    },
    {
        path: '/:lang/public/request/:uuid',
        name: publicRequestRoute,
        props: true,
        meta: {
            public: true,
        },
        component: () => import('@/views/RequestPublic.vue'),
    },
    {
        path: '/:lang/request/:documentId',
        name: requestEditRoute,
        props: true,
        component: () => import('@/views/RequestEditor.vue'),
    },
    {
        path: '/:lang/request/:documentId/create-response',
        name: responseCreateRoute,
        props: true,
        component: () => import('@/views/ResponseCreator.vue'),
    },
    {
        path: '/:lang/response/:documentId',
        name: responseEditRoute,
        props: true,
        component: () => import('@/views/ResponseEditor.vue'),
    },
    {
        path: '/:lang/request/:requestId/saved',
        name: requestSavedRoute,
        props: true,
        component: () => import('@/views/RequestSaved.vue'),
    },
    {
        path: '/:lang/qualificationapplicationresponse/:documentId',
        name: responseReviewRoute,
        props: true,
        meta: {
            public: true,
            isForwarded: false,
        },
        component: () => import('@/views/ResponseReview.vue'),
    },
    {
        path: '/:lang/previewresponse/:documentId',
        name: responsePreviewRoute,
        props: true,
        meta: {
            public: true,
            isForwarded: true,
        },
        component: () => import('@/views/ResponseReview.vue'),
    },
    {
        path: '*',
        beforeEnter() {
            window.location.href = `${store.state.configuration.hilmaWebUrl}`;
        },
    },
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});
export default router;
router.beforeEach((to, from, next) => {
    if (to.params.lang && to.params.lang !== from.params.lang) {
        if (to.params.lang === 'en') {
            VueI18n.locale = 'en-GB';
        }
        else if (to.params.lang === 'sv') {
            VueI18n.locale = 'sv-SE';
        }
        else {
            VueI18n.locale = 'fi-FI';
        }
    }
    next();
});
