import axios from 'axios';
import Vue from 'vue';
import '@/tracking/vueErrorHandler';
import { sync } from 'vuex-router-sync';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import { attachAuthenticationTokenToRequests } from './services/axios-interceptors';
import { instance } from './services/auth-service';
Vue.config.productionTip = false;
sync(store, router);
attachAuthenticationTokenToRequests(axios, async () => {
    const token = await instance.acquireToken();
    return `Bearer ${token}`;
}, (request) => {
    const isPublicUrl = !!request.url?.startsWith(store.getters.publicServiceUrl);
    const isServiceUrl = !!request.url?.startsWith(store.getters.serviceUrl);
    const requiresAuthentication = !isPublicUrl && isServiceUrl;
    return requiresAuthentication;
});
new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
