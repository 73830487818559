import axios from 'axios';
export async function getCpvCodes(lang, hilmaApiEndpoint) {
    const headers = {
        headers: {
            'cache-control': 'public',
        },
    };
    const returned = await axios.get(`${hilmaApiEndpoint}cpv/?contractType=0&lang=${lang}`, headers);
    return returned.data;
}
export async function getCpvTree(lang, hilmaApiEndpoint) {
    const headers = {
        headers: {
            'cache-control': 'public',
        },
    };
    const returned = await axios.get(`${hilmaApiEndpoint}cpv/tree?contractType=0&lang=${lang}`, headers);
    return returned.data;
}
