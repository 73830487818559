import axios from 'axios';
import { trackException } from '@/tracking/applicationinsights';
export class EcertisEvidenceContract {
    constructor(object) {
        Object.assign(this, object);
    }
    id = '';
    name = '';
    description = '';
    issuerParty = { name: '', websiteURI: '' };
    documentReference = '';
}
function mapEvidence(evidence) {
    return new EcertisEvidenceContract({
        id: evidence.Id.value,
        name: evidence.Name.value,
        description: evidence.Description.value,
        issuerParty: {
            name: evidence.EvidenceIssuerParty?.[0].PartyName[0].Name.value ?? '',
            websiteURI: evidence.EvidenceIssuerParty?.[0].WebsiteURI.value ?? '',
        },
        documentReference: evidence.EvidenceDocumentReference[0].Attachment.ExternalReference.URI.value,
    });
}
function mapEvidencesFromCriterion(criterion) {
    if (!criterion.SubCriterion) {
        return [];
    }
    return criterion.SubCriterion.flatMap((subCriterion) => subCriterion.RequirementGroup
        ?.flatMap((group) => group.TypeOfEvidence
        .flatMap(mapEvidence)) ?? []);
}
export async function getEvidencesForCriterion(criterionId, url, countryFilter = 'fi', lang = 'en') {
    try {
        const response = await axios(`${url}criteria/${criterionId}?countryFilter=${countryFilter}&lang=${lang}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        });
        const evidences = mapEvidencesFromCriterion(response.data) ?? [];
        const uniqueEvidences = new Set(evidences.map((x) => x.id));
        return Array.from(uniqueEvidences).map((x) => evidences.find((e) => e.id === x));
    }
    catch (error) {
        trackException(error, { info: 'getEvidencesForCriterion' });
        return [];
    }
}
