import axios from 'axios';
import { Resources, } from '@hankintailmoitukset.fi/hilma-espd-edm';
import { trackException } from '@/tracking/applicationinsights';
export class EspdRequest {
    id;
    title;
    uUID;
    projectId;
    noticeId;
    procedureId;
    eNoticeId;
    noticeType;
    contractType;
    hilmaTendering;
    qualificationApplicationRequest;
    language;
    lots;
    validationState;
}
export function getEspdCriteria() {
    return Resources.criterionSpecification;
}
export async function saveEspdRequest(identifier, host, payload) {
    const url = `${host}api/requests/${identifier}`;
    await axios.put(url, payload);
}
export async function getEspdRequest(identifier, host) {
    const url = `${host}api/requests/${identifier}`;
    try {
        const returned = await axios.get(url);
        return returned.data;
    }
    catch (error) {
        trackException(error, { info: 'getEspdRequest' });
        return undefined;
    }
}
