import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { isCancel } from 'axios';
let AI = null;
let apiKey;
try {
    apiKey = window.configuration.applicationInsightsKey;
}
catch (ex) {
    apiKey = null;
}
if (apiKey) {
    AI = new ApplicationInsights({
        config: {
            instrumentationKey: apiKey,
            enablePerfMgr: false,
            perfEvtsSendAll: false,
            disableCookiesUsage: true,
            enableDebug: false,
            disableExceptionTracking: false,
            autoTrackPageVisitTime: false,
            enableAutoRouteTracking: false,
            disableAjaxTracking: true,
            disableFetchTracking: true,
        },
    });
    AI.loadAppInsights();
}
export function trackException(err, details) {
    console.error(err);
    if (!AI || isCancel(err)) {
        return;
    }
    AI.trackException({
        exception: err,
    }, {
        ...details,
        url: window.location.href,
    });
}
